import * as master from '../types/master';
import * as baseService from './BaseService';

export default class OrderIntakeService extends baseService.BaseService {
    private url = '/api/OrderIntakes';

    constructor() {
        super();
    }

    async submitPurchaseOrder(command: master.OrderIntakePurchaseOrder) {
        let url = this.url + '/SaveOrderIntakePurchaseOrder';
        let response = await this.api.post(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async submitPrescription(command: master.OrderIntakePrescription) {
        let url = this.url + '/SaveOrderIntakePrescription';
        let response = await this.api.post(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async submitUploadedPurchaseOrder(formFile: File, command: master.OrderIntakeUploadPurchaseOrder) {
        let url = this.url + '/SaveOrderIntakePurchaseOrderFromUpload';
        const formData = new FormData();
        formData.append('formFile', formFile);
        formData.append('customerId', command.customerId.toString());
        formData.append('fileStoreObjectName', command.fileStoreObjectName);
        formData.append('updatedByUserId', command.updatedByUserId.toString())
        formData.append('legacyNumber', command.legacyNumber)
        formData.append('contractDeviceCount', command.contractDeviceCount.toString())
        let config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let response = await this.api.post(url, formData, config).catch(err => {
            throw err;
        });
        return response.data;
    }
    async getPurchaseOrderUploadResult(customerId: number) {
        let url = this.url + `/PurchaseOrderUploadResult/${customerId}`;
        let response = (await this.api.get(url)).data;
        return response;
    }
    async getUploadResultCollection(fileId: number, customerId: number) {
        let url = this.url + `/PurchaseOrderUploadResult/${customerId}/${fileId}`;
        let response = (await this.api.get(url)).data;
        return response;
    }
    
} 