<template>
    <div>
        <v-autocomplete v-model="model"
                        :items="results"
                        :loading="isLoading"
                        :search-input.sync="search"
                        color="gray"
                        item-text="Description"
                        item-value="ID"
                        placeholder="Search Patients"
                        prepend-icon="mdi-magnify"
                        return-object
                        no-filter
                        append-icon=""
                        @click:clear="clicked"
                        ref="searchInput"
                        clearable
                        autofocus
                        :menu-props="{ maxWidth: !isMobile ? '450' : 'auto', minWidth: !isMobile ? '450' : '0', maxHeight: '500' }">
            <template v-slot:progress>
                <v-progress-linear color="white"
                                   height="1"
                                   indeterminate
                                   absolute
                                   top></v-progress-linear>
            </template>
            <template slot="no-data">
                <v-list-item>
                    <v-list-item-subtitle v-if="onlyHintInSearch">
                        <p>
                            Enter a search term for the search hint...
                        </p>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="notFound && !isLoading && !onlyHintInSearch && search && !onlyHintInSearch">
                        <p>
                            Your search - <strong>{{search}}</strong> - did not match any
                            Patients
                        </p>
                        <div>Suggestions:</div>
                        <div>
                            <ul>
                                <li>Make sure all words are spelled correctly</li>
                                <li>Try different search terms, last name, designation etc.</li>
                            </ul>
                        </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap" v-if="(!notFound && !isLoading && !onlyHintInSearch) || !search">
                        <p>Type to start searching...</p>
                        <p class="">
                            For search hints, click or type
                            <span class="font-weight-medium">[hint]:[searchterm]</span>
                            e.g. first:john
                        </p>
                        <p>
                            <v-btn color="primary" outlined text class="text-lowercase mt-1" small @click="selectText('first:')">
                                first:
                            </v-btn>
                            <v-btn color="primary" outlined text class="text-lowercase ml-1 mt-1" small @click="selectText('last:')">
                                last:
                            </v-btn>
                            <v-btn color="primary" outlined text class="text-lowercase ml-1 mt-1" small @click="selectText('middle:')">
                                middle:
                            </v-btn>
                            <v-btn color="primary" outlined text class="text-lowercase ml-1 mt-1" small @click="selectText('phone:')">
                                phone:
                            </v-btn>
                            <v-btn color="primary" outlined text class="text-lowercase ml-1 mt-1" small @click="selectText('desig:')">
                                desig:
                            </v-btn>
                            <v-btn color="primary" outlined text class="text-lowercase ml-1 mt-1" small @click="selectText('serial:')">
                                serial:
                            </v-btn>

                        </p>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="isLoading">
                        Searching...
                    </v-list-item-subtitle>
                </v-list-item>
            </template>
            <template v-slot:item="{ item }">
                <v-list-item-avatar color="indigo"
                                    class="headline font-weight-light white--text">
                    {{item.name.charAt(0)}}
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.designation"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="item.customerName"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="item.deviceSerialNumber"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="
              item.dob != null
                ? new Date(item.dob).toLocaleDateString('en-US')
                : ''
            "></v-list-item-subtitle>
                    <template v-for="(pn, i) in item.parsedPhoneNumbers">
                        <v-list-item-subtitle v-text="
                formatUsPhone(pn.PhoneNumber) +
                (pn.Note
                  ? ' - ' + toTitleCase(pn.Note.substring(0, 50)) + ''
                  : '')
              "
                                              v-bind:key="i"></v-list-item-subtitle>
                    </template>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon v-if='item.entityType === "patient"' color="blue">mdi-account</v-icon>
                    <v-icon v-if='item.entityType === "member"'>mdi-account</v-icon>
                </v-list-item-action>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
    import GlobalSearchService from "@/services/GlobalSearchService";
    import PatientService from "@/services/PatientService";
    import VueClipboard from "vue-clipboard2";
    import VueLodash from "vue-lodash";
    import lodash, { debounce } from "lodash";
    import Vue from "vue";

    Vue.use(VueClipboard);
    Vue.use(VueLodash, {
        name: "custom",
        lodash: lodash
    });

    export default {
        data: () => ({
            results: [],
            isLoading: false,
            model: { name: "" },
            search: null,
            customerName: "",
            patientId: 0,
            selectedCustomerId: 0,
            patientSelected: false,
            customerId: 0,
            count: 0,
            selectedPatient: {
                customerId: Number,
                legacyUniqueId: String,
                firstName: String,
                lastName: String,
                customerName: String,
                patientId: Number,
                patientDesignation: String,
            },
            componentKey: 0,
            notFound: false,
            clipboardSnackbar: false,
            clipboardText: "",
            queryId: "",
            debounceMs: 500,
        }),
        created: function () {
            new GlobalSearchService().warmUpSearchCache();
            this.querySelections = debounce(this.querySelections, this.debounceMs);
        },
        computed: {
            onlyHintInSearch: function () {
                if (!this.search) {
                    return false;
                }
                return this.search[this.search.length - 1] === ":";
            },
            isMobile() {
                return this.$vuetify.breakpoint.xsOnly;
            }
        },
        watch: {
            search: function (val) {
                this.querySelections(val);
            },
            model(newModel) {
                if (newModel !== undefined) {
                    this.search = null;
                    this.firstName = newModel.firstName;
                    this.lastName = newModel.lastName;
                    this.middleName = newModel.middleName;
                    this.name = newModel.firstName + " " + newModel.middleName + (newModel.middleName ? " " : "") +
                        newModel.lastName;
                    this.customerName = newModel.customerName;
                    this.entityId = newModel.entityId;
                    this.customerId = newModel.customerId;
                    if (newModel.entityType === "patient") {
                        this.lookupPatient();
                    }
                    this.results = [];
                }
            }
        },
        methods: {
            selectText(value) {
                this.search = value;
                setTimeout(() => {
                    this.$refs.searchInput.focus();
                    this.$refs.searchInput.$el.getElementsByTagName("input")[0].setSelectionRange(value.length,
                        value.length);
                });
            },

            copyToClipboard(value) {
                let self = this;
                this.$copyText(value).then(
                    function (e) {
                        self.clipboardText = value;
                        self.clipboardSnackbar = true;
                        console.log(e);
                    },
                    function (e) {
                        console.log(e);
                    }
                );
            },

            querySelections(val) {
                if (val === "" || val === null || this.onlyHintInSearch) {
                    this.results = [];
                    return;
                }
                this.notFound = false;
                this.queryId = Date.now() + "" + Math.random();
                this.getResults(val, this.queryId);

            },
            toTitleCase(str) {
                return str.replace(/(^|\s)\S/g, function (t) {
                    return t.toUpperCase();
                });
            },
            formatUsPhone(phone) {
                let phoneTest = new RegExp(
                    /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/
                );
                phone = phone.trim();
                let results = phoneTest.exec(phone);
                if (results !== null && results.length > 8) {
                    return (
                        results[3] +
                        "-" +
                        results[4] +
                        "-" +
                        results[5] +
                        (typeof results[8] !== "undefined" ? " x" + results[8] : "")
                    );
                } else {
                    return phone;
                }
            },
            forceRerender() {
                this.componentKey += 1;
            },
            getResults: function (val, queryId) {
                if (val !== "" && val !== undefined && val !== null) {
                    this.isLoading = true;
                    let pagedOptions = {
                        Search: val,
                        SortBy: "LastName",
                        SortDesc: false,
                        ItemsPerPage: 15,
                        Page: 1
                    };
                    new GlobalSearchService()
                        .searchMembersAndPatients(pagedOptions)
                        .then((value) => {
                            if (this.queryId !== queryId) {
                                return;
                            } // Later query running
                            if (this.search) {
                                let newResults = value.items;
                                this.count = value.totalCount;
                                newResults.map((entry) => {
                                    entry.ID = entry.customerId + ":" + entry.entityId;
                                    entry.name = entry.firstName
                                        + " "
                                        + (entry.middleName ? entry.middleName : "")
                                        + (entry.middleName ? " " : "")
                                        + entry.lastName;
                                    let phoneNumbers = JSON.parse(entry.phoneNumbers);
                                    if (phoneNumbers) {
                                        entry.parsedPhoneNumbers = phoneNumbers.PhoneNumbers;
                                    }
                                    return entry;
                                });

                                if (this.queryId === queryId) {  // Only if last latest query
                                    this.results = newResults;
                                }
                                if (value.totalCount === 0) {
                                    this.notFound = true;
                                }
                            } else {
                                this.results = [];
                                this.count = 0;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => (this.isLoading = false));
                }
            },
            lookupPatient: function () {
                this.isLoading = true;
                new PatientService()
                    .get(this.customerId, this.entityId)
                    .then((value) => {
                        this.selectedPatient = value;
                        this.forceRerender();
                        this.selectedPatient.patientId = value.patientId;
                        this.selectedPatient.customerId = this.customerId;
                        this.$emit('patient-selected', this.selectedPatient);
                    })
                    .finally(() => (this.isLoading = false));
            },
            clicked: function () {
                this.results = [];
            },
        }
    };
</script>

<style scoped>
    .v-snack:not(.v-snack--absolute) {
        z-index: 9999;
    }
</style>