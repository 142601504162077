<template>
    <v-card :loading="isLoading">
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col>
                        <v-col>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row>
                                    <v-row v-show="mode !== 'View' && !na && !(mode === 'Edit' && this.existingNpi)">
                                        <v-col cols="9"><provider-input :customer-id="customerId" v-model="npi"></provider-input></v-col>
                                        <v-col cols="3"><v-btn v-show="mode !== 'View'" class="mt-3" @click="onLookup">Lookup Provider</v-btn></v-col>
                                    </v-row>
                                        <v-col v-show="mode === 'View' || (mode === 'Edit' && this.existingNpi)" cols="12"><v-text-field readonly disabled v-model="provider.npi" label="NPI"></v-text-field></v-col>
                                    <v-col cols="6"><v-checkbox v-show="mode !== 'View' && !(mode === 'Edit' && this.existingNpi)" v-model="na" label="NPI Not Applicable?"></v-checkbox></v-col>
                                    <v-col cols="6">
                                        <v-text-field v-if="(customerId == 1 || customerId == 5) && mode !== 'View'" :readonly="mode === 'View'" :disabled="mode === 'View'" :rules="[rules.required]" v-model="providerIdentifier.duz" label="Provider DUZ" required></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-if="(customerId == 1) && mode !== 'View'" :readonly="mode === 'View'" :disabled="mode === 'View'" v-model="providerSite.locationIEN" label="Location IEN"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-if="(customerId == 1) && mode !== 'View'" :readonly="mode === 'View'" :disabled="mode === 'View'" :rules="[rules.required]" v-model="providerSite.siteNumber" label="Provider Site" required></v-text-field>
                                    </v-col>
                                    <v-row :key="editKey">
                                        <v-col cols="12">
                                            <v-select :items="providerTypes"
                                                      label="Provider Type"
                                                      item-text="description"
                                                      item-value="providerTypeId"
                                                      v-model="provider.providerTypeId"
                                                      required
                                                      :rules="[rules.required]"
                                                      :readonly="mode === 'View'" :disabled="mode === 'View'"></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field v-if="mode !== 'View'" :readonly="mode === 'View'" :disabled="mode === 'View'" :rules="[rules.required]" v-model="provider.firstName" label="First Name" required></v-text-field>
                                            <v-text-field v-if="mode === 'View'" :readonly="mode === 'View'" :disabled="mode === 'View'" v-model="provider.name" label="Provider Name" required></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field v-if="mode !== 'View'" :readonly="mode === 'View'" :disabled="mode === 'View'" :rules="[rules.required]" v-model="provider.lastName" label="Last Name" required></v-text-field>
                                        </v-col>
                                        <v-col cols="4"><v-text-field :readonly="mode === 'View'" :disabled="mode === 'View'" type="tel" v-model="entityPhone.phoneNumber" v-mask="'###-###-####'" label="Phone Number"></v-text-field></v-col>
                                        <v-col cols="6"><v-text-field :readonly="mode === 'View'" :disabled="mode === 'View'" v-model="entityAddress.addressLine1" label="Address Line 1" required></v-text-field></v-col>
                                        <v-col cols="6"><v-text-field :readonly="mode === 'View'" :disabled="mode === 'View'" v-model="entityAddress.addressLine2" label="Address Line 2"></v-text-field></v-col>
                                        <v-col cols="4"><v-text-field :readonly="mode === 'View'" :disabled="mode === 'View'" v-model="entityAddress.city" label="City" required></v-text-field></v-col>
                                        <v-col cols="4"><v-select :readonly="mode === 'View'" :disabled="mode === 'View'" v-model="entityAddress.stateId" label="State" item-text="name" item-value="stateId" :items="$store.state.states"></v-select></v-col>
                                        <v-col cols="4"><v-text-field :readonly="mode === 'View'" :disabled="mode === 'View'" v-mask="'#####-####'" v-model="entityAddress.zipCode" label="Zip" required></v-text-field></v-col>
                                        <v-col cols="12" v-if="customerId !== 1"><v-checkbox :readonly="mode === 'View'" :disabled="mode === 'View'" :rules="[escalationProviderCount]" v-model="patientProvider.willReceiveReports" label="Will Receive Escalations and Reports?"></v-checkbox></v-col>
                                        <v-col cols="12"><v-checkbox readonly disabled v-if="mode === 'View'" v-model="patientProvider.isActive" label="Current Provider?"></v-checkbox></v-col>
                                        <v-col cols="12"><v-checkbox v-if="mode !== 'View' && mode !== 'Add'" v-model="patientProvider.isActive" label="Current Provider? (Note: Unchecking will inactivate Provider)"></v-checkbox></v-col>
                                    </v-row>
                                </v-row>
                            </v-form>
                            <v-dialog max-width="1200px" v-model="showLookupDialog" v-if="showLookupDialog">
                                <v-card>
                                    <v-toolbar dark color="primary">
                                        <v-btn icon dark @click="showLookupDialog = false">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                        <v-toolbar-title>Lookup Provider</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-toolbar-items>
                                            <v-btn dark text @click="showLookupDialog = false"> Close </v-btn>
                                        </v-toolbar-items>
                                    </v-toolbar>
                                    <v-card-text>
                                        <provider-search :customer-id="customerId" @selected="onSearchSelected"></provider-search>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions v-show="mode !== 'View'">
            <v-spacer></v-spacer>
            <v-btn @click="onClose">
                Cancel
            </v-btn>
            <v-btn @click="onAddSave"
                   class="primary"
                   :disabled="isLoading" :loading="isLoading">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

    import ProviderInput from "@/components/Provider/ProviderInput";
    import ProviderSearch from "@/components/Provider/ProviderSearch";
    import EntityContactService from "@/services/EntityContactService";
    import NpiService from "@/services/NpiService";
    import ProviderService from "@/services/ProviderService";
    import { EntityTypeEnum } from "@/types/enum";
    import { IdentifierTypeEnum } from "@/types/enum";

    export default {
        name: "OrderProviderAdd",
        components: { ProviderSearch, ProviderInput },
        props: {
            customerId: Number,
            entityProviders: Array,
            mode: String
        },
        data: () => ({
            showLookupDialog: false,
            na: false,
            npi: null,
            copyOfPatientProvider: null,
            copyOfProvider: null,
            valid: false,
            editKey: 0,
            isLoading: false,
            loadComplete: false,
            existingNpi: false,
            rules: {
                required: value => !!value || 'Required.',
            },
            providerTypes: [],
            entityAddress: {
                addressLine1: null,
                addressLine2: null,
                city: null,
                stateId: null,
                zipCode: null
            },
            entityPhone: {
                phoneNumber: null
            },
            providerReturnObject: {},
            provider: {
                name: null,
                firstName: null,
                lastName: null,
                npi: null,
                providerTypeId: null,
                created: null,
                providerDUZ: null,
            },
            patientProvider: {
                patientId: null,
                isActive: true,
                willReceiveReports: false,
                providerId: null
            },
            providerIdentifier: {},
            providerSite: {}
        }),
        methods: {
            escalationProviderCount(v) {
                if (this.mode === 'View') return true;
                let cnt = this.entityProviders.filter(p => p.willReceiveReports === true && p.isActive === true).length;
                if (!!v && cnt > 0 && this.patientProvider.willReceiveReports && !this.copyOfPatientProvider.willReceiveReports)
                    return 'Escalation Provider already exists for this Patient.';
                else {
                    return true;
                }
            },
            mapData() {
                this.provider.name = this.value.provider.name;
                this.provider.firstName = this.value.provider.firstName;
                this.provider.lastName = this.value.provider.lastName;
                this.provider.npi = this.value.provider.npi;
                this.provider.providerTypeId = this.value.provider.providerTypeId;
                this.provider.created = this.value.provider.created;
                this.provider.providerId = this.value.provider.providerId;
                this.providerIdentifier.duz = this.value.providerIdentifier.duz;

                if (this.value.provider.name && !this.value.provider.firstName && !this.value.provider.lastName) {
                    this.provider.lastName = this.value.provider.name;
                    this.provider.firstName = "";
                }
            },
            loadData() {
                if (this.value) {
                    this.mapData();
                    this.copyOfProvider = JSON.parse(JSON.stringify(this.provider));

                    const self = this;
                    const promises = [];
                    promises.push(new EntityContactService()
                        .getEntityAddresses(this.customerId, this.value.providerId, EntityTypeEnum.Provider)
                        .then((resp) => {
                            if (resp.length > 0) {
                                self.entityAddress = resp[0];
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        }));

                    promises.push(new EntityContactService()
                        .getEntityPhones(this.customerId, this.value.providerId, EntityTypeEnum.Provider)
                        .then((resp) => {
                            if (resp.length > 0) {
                                self.entityPhone = resp[0];
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        }));

                    const request = {
                        number: this.value.provider.npi,
                        customerId: this.customerId
                    };

                    promises.push(new NpiService().search(request)
                        .then(res => {
                            if (res.length > 0) {
                                self.npi = res[0];
                                self.npiKey = res[0].number;
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        }));

                    Promise.allSettled(promises).then(() => {
                        this.loadComplete = true;
                        this.isLoading = false;
                        this.existingNpi = !!(this.trim(this.provider.npi));
                    });
                } else {
                    this.loadComplete = true;
                }

                this.providerTypes = this.$store.state.providerTypes
                    .sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
            },
            onLookup() {
                this.showLookupDialog = true;
            },
            clearForm() {
                this.provider.name = "";
                this.provider.firstName = "";
                this.provider.lastName = "";
                this.provider.npi = "";
                this.providerIdentifier = {};
                this.providerSite = {};
                this.entityAddress.addressLine1 = "";
                this.entityAddress.addressLine2 = "";
                this.entityAddress.city = "";
                this.entityAddress.stateId = "";
                this.entityAddress.zipCode = "";
                this.entityPhone.phoneNumber = "";
                this.editKey += 1;
            },
            async isFormValid() {
                let valid = true;
                if (!this.na && !this.npi) {
                    await this.$dialog.error({
                        text: "Please enter a NPI number.",
                        title: "NPI Required",
                        actions: ['Okay'],
                    });

                    valid = false;
                }

                if ((this.entityAddress.addressLine1 || this.entityAddress.city || this.entityAddress.zipCode || this.entityAddress.stateId) &&
                    (!this.entityAddress.addressLine1 || !this.entityAddress.city || !this.entityAddress.zipCode || !this.entityAddress.stateId)) {
                    await this.$dialog.error({
                        text: "Please fill out all address fields.",
                        title: "Address Required",
                        actions: ['Okay'],
                    });

                    valid = false;
                }

                return valid;
            },
            async saveNewProvider() {
                this.provider.name = this.provider.firstName + ' ' + this.provider.lastName;
                this.provider.created = new Date().toISOString();
                let saved = true;
                const savedProvider = await new ProviderService()
                    .postProvider(this.customerId, this.provider)
                    .catch(err => {
                        console.log(err);
                        saved = false;
                    });

                if (saved) {
                    this.provider.name = this.trim(savedProvider.data.name);
                    this.provider.firstName = this.trim(savedProvider.data.firstName);
                    this.provider.lastName = this.trim(savedProvider.data.lastName);
                    this.provider.npi = this.trim(savedProvider.data.npi);
                    this.provider.providerTypeId = savedProvider.data.providerTypeId;
                    this.provider.created = this.trim(savedProvider.data.created);
                    this.provider.providerId = savedProvider.data.providerId;

                    this.providerReturnObject.providerId = savedProvider.data.providerId;
                    this.providerReturnObject.name = this.trim(savedProvider.data.name);
                }

                return saved;
            },
            async saveExistingProvider() {
                let saved = true;
                this.provider.name = this.provider.firstName + ' ' + this.provider.lastName;
                await new ProviderService()
                    .putProvider(this.customerId, this.provider.providerId, this.provider).catch(err => {
                        console.log(err);
                        saved = false;
                    });
                return saved;
            },
            async saveProviderIndentifier() {
                let saved = true;
                this.providerIdentifier.providerId = this.provider.providerId;
                this.providerIdentifier.identifierTypeId = IdentifierTypeEnum.DUZ;
                this.providerIdentifier.identifierValue = this.providerIdentifier.duz;
                await new ProviderService()
                    .postProviderIdentifier(this.customerId, this.providerIdentifier).catch(err => {
                        console.log(err);
                        saved = false;
                    });
                return saved;
            },

            async saveProviderSite() {
                let saved = true;
                this.providerSite.providerId = this.provider.providerId;
                this.providerSite.providerDUZ = this.providerIdentifier.duz;
                if (this.providerSite.locationIEN) {
                    await new ProviderService()
                        .postProviderSite(this.customerId, this.providerSite).catch(err => {
                            console.log(err);
                            saved = false;
                        });
                }
                return saved;
            },
            async getProvidersByNpi(npi) {
                let providers = [];
                const request = {
                    number: npi,
                    customerId: this.customerId
                };
                await new NpiService().search(request)
                    .then(res => {
                        if (res.length > 0) {
                            providers = res;
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });
                return providers;
            },
            isNpiSavedToDatabase(providers) {
                return providers.some(e => !e.fromRegistry);
            },
            getNpiSavedToDatabase(providers) {
                return providers.find(e => !e.fromRegistry);
            },
            trim(val) {
                if (val) return val.trim();
            },
            npiSelected() {
                return !!(this.trim(this.provider.npi));
            },
            async save() {
                try {
                this.isLoading = true; 
                let identifierResult = true;
                let siteResult = true;

                let providerSaveResult;
                if (this.provider.providerId > 0) {
                    providerSaveResult = await this.saveExistingProvider();
                } else {
                    providerSaveResult = await this.saveNewProvider();
                }

                if (this.providerIdentifier.duz != undefined) {
                    identifierResult = await this.saveProviderIndentifier();
                    siteResult = await this.saveProviderSite();
                }

                return providerSaveResult && identifierResult && siteResult
                    && (this.entityAddress.entityAddressId > 0 ? await this.saveExistingAddress() : await this.saveNewAddress())
                    && (this.entityPhone.entityPhoneId > 0 ? await this.saveExistingPhone() : await this.saveNewPhone());
                } finally {
                this.isLoading = false; 
                }
            },
            async getProvider(providerId) {
                return await new ProviderService().get(this.customerId, providerId)
                    .catch(err => {
                        console.log(err)
                    });
            },
            async onAddSave() {
                if (!this.$refs.form.validate() || !(await this.isFormValid())) {
                    return;
                }
                this.isLoading = true;
                let existingProviders = this.npiSelected() ? await this.getProvidersByNpi(this.trim(this.provider.npi)) : [];
                const npiExists = !!(this.npiSelected() && this.isNpiSavedToDatabase(existingProviders));

                let saved = false;
                if (this.mode === 'Add' && !npiExists) {
                    saved = await this.save();
                }

                if (this.mode === 'Add' && npiExists) {
                    const existingProvider = await this.getProvider(this.getNpiSavedToDatabase(existingProviders).providerId);
                    this.provider.providerId = existingProvider.providerId;
                    this.provider.created = existingProvider.created;
                    saved = await this.save();
                }

                // Saving existing NPI or saving record without NPI
                if (this.mode === 'Edit' && ((this.existingNpi && this.trim(this.provider.npi)) || (!this.existingNpi && !this.trim(this.provider.npi)))) {
                    saved = await this.save();
                }

                // Added NPI to existing record
                if (this.mode === 'Edit' && !this.existingNpi && this.trim(this.provider.npi)) {
                    await this.$dialog.info({
                        text: "We found a provider that matches the NPI in the database. The old provider will removed and the existing provider will be added to this patient.",
                        title: "Provider Match Found",
                        actions: ['Okay']
                    });

                    let newProviderId = this.provider.providerId;
                    if (this.isNpiSavedToDatabase(existingProviders)) {
                        const databaseNpi = this.getNpiSavedToDatabase(existingProviders)
                        const existingProvider = await this.getProvider(databaseNpi.providerId);
                        saved = !!existingProvider;
                        newProviderId = existingProvider.providerId;
                    } else {
                        this.provider.providerId = 0;
                        saved = await this.saveNewProvider();
                    }
                }

                if (saved) {
                    this.isLoading = false;
                    await this.$dialog.info({
                        text: "Provider was created successfully.",
                        title: "Created",
                        actions: ['Okay']
                    });
                    this.$emit("saved", this.providerReturnObject);
                    this.providerReturnObject = {};
                } else {
                    this.isLoading = false;
                    await this.$dialog.error({
                        text: "Provider was not saved successfully.",
                        title: "Error",
                        actions: ['Okay']
                    });
                }
                this.clearForm();
            },
            onClose() {
                this.$emit('closed');
            },
            async onSearchSelected(val) {
                this.showLookupDialog = false;
                this.npi = val;
            },
            async saveExistingAddress() {
                let saved = true;
                this.entityAddress.updatedByUserId = this.$store.state.achillesUser.userId;

                await new EntityContactService().putPatientAddress(this.customerId, this.entityAddress.entityAddressId, this.entityAddress)
                    .catch(err => {
                        saved = false;
                        console.log(err);
                    });

                return saved;
            },
            async saveExistingPhone() {
                let saved = true;
                this.entityPhone.updatedByUserId = this.$store.state.achillesUser.userId;

                await new EntityContactService().putPatientPhone(this.customerId, this.entityPhone.entityPhoneId, this.entityPhone).catch(err => {
                    saved = false;
                    console.log(err);
                });

                return saved;

            },
            async saveNewAddress() {
                if (!(this.entityAddress.addressLine1 && this.entityAddress.zipCode && this.entityAddress.city && this.entityAddress.stateId)) return true;
                let saved = true;
                this.entityAddress.entityAddressId = 0;
                this.entityAddress.updatedByUserId = this.$store.state.achillesUser.userId;
                this.entityAddress.created = new Date().toISOString();
                this.entityAddress.entityId = this.provider.providerId;
                this.entityAddress.entityTypeId = EntityTypeEnum.Provider;
                this.entityAddress.isResidentialAddress = false;
                this.entityAddress.isMailingAddress = false;

                const newEntityAddress = await new EntityContactService().postPatientAddress(this.customerId, this.entityAddress)
                    .catch(err => {
                        saved = false;
                        console.log(err);
                    });

                if (saved && newEntityAddress.data && newEntityAddress.data.addressLine1) {
                    this.entityAddress = newEntityAddress.data;
                }

                return saved;
            },
            async saveNewPhone() {
                if (!(this.entityPhone)) return true;
                let saved = true;
                this.entityPhone.entityPhoneId = 0;
                this.entityPhone.updatedByUserId = this.$store.state.achillesUser.userId;
                this.entityPhone.created = new Date().toISOString();
                this.entityPhone.entityId = this.provider.providerId;
                this.entityPhone.entityTypeId = EntityTypeEnum.Provider;
                this.entityPhone.bestNumberToCall = true;
                this.entityPhone.phoneType = "Phone";

                const newEntityPhone = await new EntityContactService().postPatientPhone(this.customerId, this.entityPhone).catch(err => {
                    saved = false;
                    console.log(err);
                });

                if (saved && newEntityPhone.data && newEntityPhone.data.phoneNumber) {
                    this.entityPhone = newEntityPhone.data;
                }

                return saved;
            }
        },
        created() {
            this.loadData();
        },
        computed: {
            entityAddressState: {
                get() {
                    if (!this.npi || this.npi.addresses.length < 1) return "";
                    return this.$store.state.states.find(s => s.name === this.npi.addresses[0].state).stateId;
                }
            }
        },
        watch: {
            async npi(val) {
                if (!this.loadComplete) return;
                if (val) {
                    this.provider.name = this.trim(val.firstName) + ' ' + this.trim(val.lastName);
                    this.provider.firstName = this.trim(val.firstName);
                    this.provider.lastName = this.trim(val.lastName);
                    this.provider.npi = this.trim(val.number);
                    this.entityAddress.addressLine1 = this.trim(val.addresses[0].addressLine1);
                    this.entityAddress.addressLine2 = this.trim(val.addresses[0].addressLine2);
                    this.entityAddress.city = this.trim(val.addresses[0].city);
                    this.entityAddress.stateId = this.entityAddressState;
                    this.entityAddress.zipCode = this.trim(val.addresses[0].zip).replace(/(\d{5})(\d{4})/, "$1-$2");
                    this.entityPhone.phoneNumber = this.trim(val.addresses[0].phoneNumber);
                } else {
                    this.clearForm();
                }
            },
            async na(val) {
                if (!this.loadComplete) return;
                if (val) {
                    const result = await this.$dialog.warning({
                        text: "You have stated the provider does not have a NPI. Look up the provider to confirm this is accurate. Note NPI ensures uniqueness for Providers. Would you like to continue?",
                        title: "Confirm NPI Not Applicable",
                        actions: ['Yes', 'No'],
                    });

                    if (result === 'Yes') {
                        this.npi = null;
                        this.clearForm();
                    } else {
                        this.na = false;
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>