<template>
    <v-card-text>
        <v-container>
            <form>
                <v-col>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-h6"><strong>Patient:</strong> {{ patientFirstName }} {{ patientLastName }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-select :items="voidReasonItems"
                                      @change="showVoidOrderNoteCheck"
                                      v-model="voidOrderValues.voidReason"
                                      label="Voided Reason"
                                      outlined>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="showVoidOrderNote">
                        <v-col cols="12">
                            <v-textarea v-model="voidOrderValues.voidNote"
                                        outlined
                                        label="Notes (required)">
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row class="justify-end">
                        <v-card-actions>
                            <v-btn color="primary"
                                   dark
                                   :disabled="loading"
                                   @click="checkVoidForm">
                                Save
                            </v-btn>
                            <v-btn color="primary"
                                   dark
                                   text
                                   @click="onClose">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                    <v-row>
                        <div class="text-danger">{{ validationMessage }}</div>
                    </v-row>
                </v-col>
            </form>
        </v-container>
    </v-card-text>
</template>

<script>

    export default {
        name: "VoidReason",
        props: {
            patientFirstName: String,
            patientLastName: String,
        },
        data: () => ({
            loading: false,
            showVoidOrderNote: false,
            validationMessage: null,
            voidOrderValues: {},
            voidReasonItems: [
                { value: 'Deceased', text: 'Deceased' },
                { value: 'Declined', text: 'Declined' },
                { value: 'Duplicate', text: 'Duplicate' },
                { value: 'FulfillmentIssue', text: 'Fulfillment Issue' },
                { value: 'IneligibleCoverage', text: 'Ineligible - Coverage' },
                { value: 'IneligibleHealthCriteria', text: 'Ineligible - Health Criteria' },
                { value: 'InvalidRx', text: 'Invalid Rx' },
                { value: 'ProviderRevoked', text: 'Provider Revoked' },
                { value: 'Unreachable', text: 'Unreachable' },
                { value: 'Unknown', text: 'Unknown' }
            ],
        }),
        methods: {
            checkVoidForm(e) {
                this.loading = true;
                if (!this.voidOrderValues.voidReason) {
                    this.validationMessage = 'Void reasons is required';
                    this.loading = false;
                } 
                else if (this.voidOrderValues.voidReason && this.showVoidOrderNote && !this.voidOrderValues.voidNote) 
                {
                    this.validationMessage = 'This void reason requires a note';
                    this.loading = false;
                }
                else 
                {
                    this.saveVoid();
                }

                e.preventDefault();
            },  

            showVoidOrderNoteCheck(item) {
                switch (item) {
                    case 'Deceased':
                    case 'Declined':
                    case 'Duplicate':
                    case 'IneligibleCoverage':
                    case 'IneligibleHealth Criteria':
                    case 'Unreachable':
                        this.showVoidOrderNote = false;
                        break;
                    case 'FulfillmentIssue':
                    case 'InvalidRx':
                    case 'ProviderRevoked':
                        this.showVoidOrderNote = true;
                        break;
                }
            },

            saveVoid() {
                this.validationMessage = null;
                this.$emit("saved", this.voidOrderValues);
                this.showVoidOrderNote = false;
                this.validationMessage = null;
            },

            onClose() {
                this.loading = false;
                this.showVoidOrderNote = false;
                this.validationMessage = null;
                this.$emit('closed');
            },
        }
    }
</script>

<style>
    .text-danger {
        color: #983a37;
    }
</style>
