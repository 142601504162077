<template>
    <div>
        <v-autocomplete v-model="model"
                        :items="results"
                        :loading="isLoading"
                        :search-input.sync="search"
                        color="gray"
                        item-text="Description"
                        item-value="ID"
                        placeholder="Search Providers"
                        prepend-icon="mdi-magnify"
                        return-object
                        no-filter
                        append-icon=""
                        @click:clear="clicked"
                        ref="searchInput"
                        clearable
                        autofocus
                        :menu-props="{ maxWidth: !isMobile ? '450' : 'auto', minWidth: !isMobile ? '450' : '0', maxHeight: '500' }">
            <template v-slot:progress>
                <v-progress-linear color="white"
                                   height="1"
                                   indeterminate
                                   absolute
                                   top></v-progress-linear>
            </template>
            <template slot="no-data">
                <v-list-item>
                    <v-list-item-subtitle v-if="onlyHintInSearch">
                        <p>
                            Enter a search term for the search hint...
                        </p>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="notFound && !isLoading && !onlyHintInSearch && search && !onlyHintInSearch">
                        <p>
                            Your search - <strong>{{search}}</strong> - did not match any
                            Providers
                        </p>
                        <div>Suggestions:</div>
                        <div>
                            <ul>
                                <li>Make sure all words are spelled correctly</li>
                                <li>Try different search terms, last name, designation etc.</li>
                            </ul>
                        </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap" v-if="(!notFound && !isLoading && !onlyHintInSearch) || !search">
                        <p>Type to start searching...</p>
                        <p class="">
                            For search hints, click or type
                            <span class="font-weight-medium">[hint]:[searchterm]</span>
                            e.g. first:john
                        </p>
                        <p>
                            <v-btn color="primary" outlined text class="text-lowercase mt-1" small @click="selectText('first:')">
                                first:
                            </v-btn>
                            <v-btn color="primary" outlined text class="text-lowercase ml-1 mt-1" small @click="selectText('last:')">
                                last:
                            </v-btn>
                            <v-btn color="primary" outlined text class="text-lowercase ml-1 mt-1" small @click="selectText('NPI:')">
                                NPI:
                            </v-btn>
                            <v-btn v-if="customerId === 1" color="primary" outlined text class="text-lowercase ml-1 mt-1" small @click="selectText('DUZ:')">
                                DUZ:
                            </v-btn>
                        </p>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="isLoading">
                        Searching...
                    </v-list-item-subtitle>
                </v-list-item>
            </template>
            <template v-slot:item="{ item }">
                <v-list-item-avatar color="indigo"
                                    class="headline font-weight-light white--text">
                    {{item.name.charAt(0)}}
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.npi"></v-list-item-subtitle>
                    <v-list-item-subtitle v-if="customerId === 1" v-text="item.providerDUZ"></v-list-item-subtitle>
                    <v-list-item-subtitle v-if="customerId === 1" v-text="item.locationIEN"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="providerType(item.providerTypeId)"></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
    import GlobalSearchService from "../services/GlobalSearchService";
    import ProviderService from "../services/ProviderService"
    import VueClipboard from "vue-clipboard2";
    import VueLodash from "vue-lodash";
    import lodash, { debounce } from "lodash";
    import { ProviderTypeEnum } from "../types/enum";
    import Vue from "vue";

    Vue.use(VueClipboard);
    Vue.use(VueLodash, {
        name: "custom",
        lodash: lodash
    });

    export default {
        props: {
            customerId: Number,
            patientSiteId: Number,
        },
        data: () => ({
            results: [],
            isLoading: false,
            model: { name: "" },
            search: null,
            //customerId: null,
            providerID: 0,
            selectedCustomerId: 0,
            providerSelected: false,
            providerId: 0,
            count: 0,
            provider: {
                customerId: null,
                providerId: null,
                name: null,
                NPI: null,
                pointOfContactName: null,
                pointOfContactRoleName: null,
                preferredModeOfContactId: null,
                officeInformationNote: null,
                providerTypeId: null,
                updatedByUserId: null,
                providerTypeName: null,
                firstName: null,
                lastName: null,
                note: null,
            },
            componentKey: 0,
            notFound: false,
            clipboardSnackbar: false,
            clipboardText: "",
            queryId: "",
            debounceMs: 500,
        }),
        created: function () {
            this.querySelections = debounce(this.querySelections, this.debounceMs);
        },
        computed: {
            onlyHintInSearch: function () {
                if (!this.search) {
                    return false;
                }
                return this.search[this.search.length - 1] === ":";
            },
            isMobile() {
                return this.$vuetify.breakpoint.xsOnly;
            }
        },
        watch: {
            search: function (val) {
                this.querySelections(val);
            },
            model(newModel) {
                if (newModel !== undefined) {
                    this.search = null;
                    this.firstName = newModel.firstName;
                    this.lastName = newModel.lastName;
                    this.NPI = newModel.NPI;
                    this.name = newModel.lastName == "" ? newModel.name : newModel.firstName + " " + newModel.lastName;
                    this.providerId = newModel.providerId;
                    this.lookupProvider();
                    this.results = [];
                }
            }
        },
        methods: {
            selectText(value) {
                this.search = value;
                setTimeout(() => {
                    this.$refs.searchInput.focus();
                    this.$refs.searchInput.$el.getElementsByTagName("input")[0].setSelectionRange(value.length,
                        value.length);
                });
            },

            copyToClipboard(value) {
                let self = this;
                this.$copyText(value).then(
                    function (e) {
                        self.clipboardText = value;
                        self.clipboardSnackbar = true;
                        console.log(e);
                    },
                    function (e) {
                        console.log(e);
                    }
                );
            },

            providerType(item) {
                if (item) {
                    return ProviderTypeEnum[item];
                }
                return "Unknown";
            },

             querySelections(val) {
                if (val === "" || val === null || this.onlyHintInSearch) {
                    this.results = [];
                    return;
                }
                this.notFound = false;
                this.queryId = Date.now() + "" + Math.random();
                this.getResults(val, this.queryId);

            },
            toTitleCase(str) {
                return str.replace(/(^|\s)\S/g, function (t) {
                    return t.toUpperCase();
                });
            },

            forceRerender() {
                this.componentKey += 1;
            },
            getResults: function (val, queryId) {
                if (val !== "" && val !== undefined && val !== null) {
                    this.isLoading = true;
                    let pagedOptions = {
                        Search: val,
                        SortDesc: false,
                        ItemsPerPage: 15,
                        Page: 1
                    };
                let searchService;
                if (this.customerId === 1) {
                    searchService = new GlobalSearchService().SearchVAProvidersByPatientSiteId(this.$props.customerId, this.patientSiteId, pagedOptions);
                } else {
                    searchService = new GlobalSearchService().searchProviders(this.$props.customerId, pagedOptions);
                }
                searchService.then((value) => {
                    if (this.queryId !== queryId) {
                        return; // Later query running
                    }
                    if (this.search) {
                        let newResults = value.items;
                        this.count = value.totalCount;
                        newResults = newResults.map((entry) => {
                            entry.npi = "NPI: " + entry.npi;
                            if (entry.lastName != null) {
                                entry.name = entry.firstName + " " + entry.lastName;
                            }
                            entry.providerDUZ = "DUZ: " + entry.providerDUZ;
                            entry.locationIEN = "Location IEN: " + entry.locationIEN;
                            return entry;
                        });
                        if (this.queryId === queryId) {  // Only if last latest query
                            this.results = newResults;
                        }
                        if (value.totalCount === 0) {
                            this.notFound = true;
                        }
                    } else {
                        this.results = [];
                        this.count = 0;
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.isLoading = false));
                }
            },
            lookupProvider: function () {
                this.isLoading = true;
                new ProviderService()
                    .get(this.$props.customerId, this.providerId)
                    .then((value) => {
                        if (value.providerId != null) {
                            this.provider = Object.assign({}, value);
                            this.provider.providerId = value.providerId;
                            this.provider.customerId = this.$props.customerId;
                            this.provider.name = value.name;
                            this.$emit('provider-selected', this.provider);
                        } else {
                            this.showAddProvider = true;
                        }
                    })
                    .finally(() => (this.isLoading = false));
            },
            clicked: function () {
                this.results = [];
            },
        }
    };
</script>

<style scoped>
    .v-snack:not(.v-snack--absolute) {
        z-index: 9999;
    }
</style>